import React, {useState, useEffect} from 'react';
import {signOut, getAuth} from "firebase/auth";
import {useAuthState} from '../Firebase';

import {Navbar, Nav, Container, Button} from 'react-bootstrap';
import {useParams} from "react-router-dom";

const Navigation = (props) => {
  useEffect(() => {

  }, [])

  const authState = useAuthState();
  const auth = getAuth();
  const {eid} = useParams();

  return (
    <Navbar className="navbar-custom" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/">Smart Charge Controller</Navbar.Brand>
        <Nav className="me-auto">
          {/*<Nav.Link href="/">Home</Nav.Link>*/}
        </Nav>
        {authState.user !== undefined && authState.user !== null && (
          <Nav>
            <Nav.Link href="#" onClick={() => {
              signOut(auth)
            }}>Logout</Nav.Link>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
}

export default Navigation;