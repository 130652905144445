import React from 'react';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref } from "firebase/storage";

import { useState, useEffect, useContext, createContext } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCAzcdIb3-t5p6AMIiDcv74CLBQK5QZ7Bo",
  authDomain: "app.smartchargecontroller.ch",
  projectId: "smartchargecontroller-f464a",
  storageBucket: "smartchargecontroller-f464a.appspot.com",
  messagingSenderId: "512006469777",
  appId: "1:512006469777:web:393bfc85f48aa79fab6730",
  measurementId: "G-EW0Z04GXJ4"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage();
const functions = getFunctions(firebaseApp, 'europe-west1');


const AuthContext = createContext();

const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
};

const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}

// get Easee Sites
const getEaseeSites = async (username, password) => {
  const data = {};
  const getSites = httpsCallable(functions, 'getEaseeSites'); // Updated this line
  try {
    const res = await getSites({userName: username, password: password}); 
    if(res.data) {
      data.sites = res.data.sites;
      data.accessToken = res.data.accessToken;
    } else {
      data.error = "No data received";
    }
  } catch (error) {
    data.error = error;
  }
  return data;
}

// get Easee site structure
const getEaseeSite = async(siteId, accessToken) => {
  const data = {};  
  const getSite = httpsCallable(functions, 'getEaseeSite'); // Updated this line
  try {
    const res = await getSite({accessToken: accessToken, siteId: siteId});
    if(res.data) {
      data.site = res.data;
    } else {
      data.error = "No data received";
    }
  } catch (error) {
    data.error = error;
  }
  return data
}

const newEdge = async (edgeData) => {
  const addEdge = httpsCallable(functions, 'createNewEdge'); // Updated this line
  try {
    const res = await addEdge(edgeData);
    return res.data;
  } catch (error) {
    return {error: error};
  }
}

export {firebaseApp, db, storage, AuthContext, AuthContextProvider, useAuthState, getEaseeSites, getEaseeSite, newEdge} ;
